/*
=============== 
Variables
===============
*/

:root {
  --clr-primary: #2680c0;
  --clr-primary-dark: hsl(205, 100%, 21%);
  --clr-primary-light: hsl(205, 84%, 74%);
  --clr-grey-1: #102a42;
  --clr-grey-5: #617d98;
  --clr-grey-10: #f1f5f8;
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.25rem;
  --large-screen-width: 1170px;
  --small-screen-width: 90vw;
  --fixed-width: 50rem;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
  display: block;
}
.cart-div {
  /* background: #eec2c2; */
  background: #13547a;
  width: 100%;
  height: 100%;
}
.cart-div h1,
.cart-div h2,
.cart-div h3,
.cart-div h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 2rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/* more global css */

.cart-div.btn {
  text-transform: uppercase;
  background: var(--clr-primary);
  color: var(--clr-white);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  transition: var(--transition);
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
.btn:hover {
  color: var(--clr-primary);
  background: var(--clr-primary-light);
}

/*
=============== 
Navbar
===============
*/
.loading {
  text-align: center;
  margin-top: 5rem;
}
.cartNav {
  /* background: var(--clr-primary); */
  background: transparent;
  padding: 1.25rem 2rem;
  margin-bottom: 40px;
}
.navkat-center {
  max-width: var(--fixed-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cartNav h3 {
  margin-bottom: 0;
  color: var(--clr-white);
}
.navkat-container {
  display: block;
  position: relative;
}
.cartNav svg {
  width: 2rem;
  fill: var(--clr-white);
}
.amountkat-container {
  position: absolute;
  top: -0.85rem;
  right: -0.85rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: var(--clr-primary-light);
  display: flex;
  align-items: center;
  justify-content: center;
}
.totalkat-amount {
  color: var(--clr-white);
  margin-bottom: 0;
  font-size: 1.25rem;
}
.bodaLine {
  width: 2px;
  height: 40px;
  background: rgb(241, 104, 6);
}
/*
=============== 
Cart
===============
*/
.cart {
  min-height: calc(100vh - 120px);
  width: 90vw;
  margin: 0 auto;
  margin-top: 40px;
  padding: 2.5rem 15px;
  max-width: var(--fixed-width);
  background: rgb(210, 173, 236);
}
.cart h2 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
  /* color: aquamarine; */
  color: rgb(241, 104, 6);
}

.empty-cart {
  text-transform: lowercase;
  color: var(--clr-grey-5);
  margin-top: 1rem;
  text-align: center;
}
.cart footer {
  margin-top: 4rem;
  text-align: center;
}
.cart-total h4 {
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.clear-btn {
  background: transparent;
  padding: 0.5rem 1rem;
  color: white;
  /* border: 1px solid aquamarine; */
  background: rgb(241, 104, 6);
  margin-top: 2.25rem;
  font-weight: bold;
  transition: 1s;
}
.clear-btn:hover {
  background: transparent;
  color: white;
  border: 2px solid rgb(241, 104, 6);
  
}
@media(max-width: 450px) {
  .clear-btn {
    font-size: 12px;
  }
}

/*
=============== 
Cart Item
===============
*/
.cart-item {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 1.5rem;
  margin: 1.5rem 0;
}
.cart-item img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}
.cart-item h4 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  letter-spacing: 2px;
  /* color: #f5f529; */
}
.itemTitle {
  /* color: #f5f529; */
  color: white;
}
.item-price {
  color: #812d2d;
  
}
.binBtn {
  /* color: var(--clr-primary); */
  color: rgb(241, 104, 6);
  letter-spacing: var(--spacing);
  cursor: pointer;
  font-size: 0.85rem;
  /* background: transparent; */
  /* background: #f5f529; */
  /* background: aquamarine; */
  background: #13547a;
  border: none;
  margin-top: 0.375rem;
  transition: var(--transition);
  padding: 5px;
  border-radius: 10px 0px 10px 0px;

}
.empty-furn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.reload-items {
  padding: 8px 15px;
  background: rgb(241, 104, 6);
  border: none;
  color: var(--clr-white);
  border-radius: 8px;
  font-weight: bolder;
  text-align: center;
  margin-top: 40px;
  letter-spacing: 2px;
  cursor: pointer;
  transition: background 2s ease;
}
.reload-items:hover {
  background: rgb(243, 146, 77);
  border: 2px solid rgb(241, 104, 6);
  

}

.binBtn:hover {
  background: rgb(241, 104, 6);
  color: white;


}
.remove-btn:hover {
  color: var(--clr-primary-light);
}
.amount-btn {
  width: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
}
.amount-btn svg {
  fill: var(--clr-primary);
}
.amount-btn:hover svg {
  fill: var(--clr-primary-light);
}
.amount {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1;
  /* color: aquamarine; */
  color: white;
}
hr {
  background: var(--clr-grey-5);
  border-color: transparent;
  border-width: 0.25px;
}
@media(max-width: 270px) {
  .navkat-center {
    display: flex;
    flex-direction: column;
  }
  .bodaLine {
    width: 0;
    height: 0;
  }
  .navkat-container {
    margin-top: 25px;
  }
  header h2 {
    font-size: 14px;
  }
  .cart-item {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .pricePosition {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  /* .cart-item img {
    width: 100%;
    height: 70%;
  } */
}