/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.btn {
  background: var(--clr-primary-5);
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius);
  text-transform: capitalize;
  color: var(--clr-white);
  letter-spacing: var(--spacing);
  border-color: transparent;
  cursor: pointer;
  margin-top: 2rem;
  font-size: 1.2rem;
}
/*
=============== 
Facts
===============
*/
main {
  width: 90vw;
  max-width: var(--fixed-width);
  margin: 5rem auto;
}
.loading {
  text-align: center;
}
.main-title {
  text-align: center;
  margin-bottom: 4rem;
}

.underline {
  width: 6rem;
  height: 0.25rem;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}

.singleFact {
  /* background: var(--clr-white); */
  /* background: rgb(216, 184, 184); */
  background: rgb(219, 168, 168);

  border-radius: var(--radius);
  /* margin: 2rem 0; */
  box-shadow: var(--light-shadow);
  transition: var(--transition);
  width: 65%;
  margin: auto;
  margin-bottom: 2rem;
  transition: transform 0.7s ease ease-in;
}
.singleFact:hover {
  box-shadow: var(--dark-shadow);
}
/* <<<<<<<<<<<SLIDE>>>>>>>>>>>>>>>> */
.tg-left {
  transform: translateX(-200%);
}
.tg-right {
  transform: translateX(200%);
}
.centered {
  transform: translateX(0%);
}
/* <<<<<<<<<<<<SLIDE>>>>>>>>>>>>>>>>> */
.factTitle {
  color: rgb(63, 71, 68);
}
.singleFact img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-top-right-radius: var(--radius);
  border-top-left-radius: var(--radius);
}
.fact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}
.fact-info h4 {
  margin-bottom: 0;
}
.fact-description {
  color: #fff;
}
.singleFact button {
  background: transparent;
  border-color: transparent;
  text-transform: capitalize;
  color: var(--clr-primary-5);
  font-size: 1rem;
  cursor: pointer;
  padding-left: 0.25rem;
}
.tour-price {
  color: var(--clr-primary-5);
  background: var(--clr-primary-10);
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius);
}
.singleFact footer {
  padding: 1.5rem 2rem;
}
.singleFact .fact-btn {
  display: block;
  width: 200px;
  margin: 1rem auto 0 auto;
  /* color: var(--clr-red-dark); */
  color: white;
  letter-spacing: var(--spacing);
  /* background: #9ac189; */
  background-color: rgb(241, 104, 6);
  border: 1px solid var(--clr-red-dark);

  padding: 0.25rem 0.5rem;
  /* border-radius: var(--radius); */
  border-radius: 10px 0px 10px 0px;
  font-weight: bolder;
}
.singleFact .fact-btn:hover {
  background-color: rgb(247, 161, 100);
  border: 1px solid rgb(241, 104, 6);
  color: #13547a;
  
}
.singleFact .ml-btn {
  color: rgb(241, 104, 6);
}
@media(max-width: 800px) {
  .singleFact {
    width: 80%;
    margin-top: -50px;
  }

}
@media(max-width: 500px) {
  .main-title h2 {
    font-size: 15px;

  }
  .ReloadDiv {
    width: 80%;
    height: 40%;
    font-size: 10px;
    text-align: center;

  }
  .ReloadDiv h2 {
    font-size: 20px;

  }
  .ReloadDiv button {
    font-size: 15px;
    padding: 1px 0px;
    width: 90px;

  }
  .tg-left {
    transform: translateX(0%);
  }
  .tg-right {
    transform: translateX(0%);
  }
  .singleFact footer {
    /* padding: 1.5rem 2rem; */
    padding: 35px 10px;
  }


  

}

@media(max-width: 500px) {
  .singleFact img {
    height: 10rem;
  }
  .singleFact .fact-btn {
    display: block;
    width: 100%;
    font-size: 12px;
  }

}
@media(max-width: 450px) {
  .singleFact {
    width: 100%;
  }

}

