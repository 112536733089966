/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  /* --clr-primary-10: hsl(205, 100%, 96%); */
  --clr-primary-10: hsl(351, 61%, 48%);

  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);

  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  overflow-x: hidden;
  position: relative;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Navbar
===============
*/
nav {
  position: sticky;
  background: #80d0c7;
  /* background: var(--clr-white); */
  /* box-shadow: var(--light-shadow); */
  box-shadow: 0 4px 8px rgba(0,0,0, .3);
  z-index: 3;
  
}
.homeTag {
  color: rgb(241, 104, 6);
  
  background-color: #13547a;
  padding: 10px;
  border-radius: 15px 0px 15px 0px;
}
.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.nav-toggle {
  font-size: 1.5rem;
  /* color: var(--clr-primary-5); */
  color: rgb(241, 104, 6);
  background: transparent;
  border-color: transparent;
  transition: var(--transition);
  cursor: pointer;
}
.change-navColor {
  color: red;
  /* border: 2px solid red; */
}
.nav-toggle:hover {
  color: var(--clr-primary-1);
  transform: rotate(90deg);
}
.logo {
  height: 40px;
}
.links a {
  color: var(--clr-grey-3);
  /* color: #13547a; */
  /* color: rgb(191, 255, 64); */
  font-size: 1.5rem;
  text-transform: capitalize;
  letter-spacing: var(--spacing);
  display: block;
  padding: 0.5rem 1rem;
  transition: var(--transition);
  
}
.links li a::after {
  content: "";
  width: 0%;
  height: 2px;
  background: rgb(241, 104, 6);
  display: block;
  margin: auto;
  transition: 0.5s;
}
.links li a:hover::after {
  width: 100%;
}

.links a:hover {
  /* background: var(--clr-primary-8); */
  background: #13547a;
  /* color: var(--clr-primary-5); */
  color: rgb(241, 104, 6);
  padding-left: 1.5rem;
}
.social-icons {
  display: none;
}
.links-container {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
}
.show-container {
  height: 10rem;
}
@media(max-width: 700px) {
  .homeTag {
    font-size: 20px;
  }
}
@media(max-width: 400px) {
  .homeTag {
    font-size: 15px;
  }

  .links li a {
    font-size: 15px;
  }
  
  .links li a:hover::after {
    width: 0%;

  }
  .links li a:hover {
    color: rgb(241, 104, 6);
  }
}

@media screen and (min-width: 800px) {
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
  }
  .links {
    display: flex;
  }
 
  
  .links a {
    padding: 0;
    margin: 0 0.5rem;
  }
  .links a:hover {
    padding: 0;
    background: transparent;
  }
  .social-icons {
    display: flex;
  }
  .social-icons a {
    margin: 0 0.5rem;
    color: var(--clr-primary-5);
    transition: var(--transition);
  }
  .social-icons a:hover {
    color: var(--clr-primary-7);
  }
}
@media(max-width: 190px) {
  .nav-header {
    flex-direction: column;
  }
  
}

/* HOME-LINKS */

/* ****************************start of HOME-PORTFOLIO*********************** */

.port-f {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(#80d0c7, #13547a);
  overflow: hidden;

}

.port-description {
  position: absolute;
  width: 70%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: #fff;
  /* background: yellow; */
}
.port-description h1 {
  /* color: rgb(241, 104, 6); */
  color: rgb(188, 80, 4);


}
.port-description p {
  color: #fff;
}
.tytles {
  color: rgb(188, 80, 4);
}
.the {
  font-size: 20px;
  color: #fff;
  text-transform: lowercase;
}
.roller {
  width: 100%;
  height: 110px;
  /* background-color: rgb(234, 158, 65); */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0,0,0, .3);

}
.roller-div {
  width: 70px;
  height:70px;
  /* background: rgb(138, 214, 24); */
  background: #13547a;
  /* background: #0497eb; */
  border: 2px solid rgb(241, 104, 6);

  border-radius: 15px;
}
.roller-div2 {
  width: 0px;
  height:0px;
  

}
.hire-Outerdiv {
  height: 100%;
  max-width: 17%;
  margin: auto;
}
.hire-div {
  height: 100%;
  max-width: 140px;
  margin: auto;
  text-align: center;
  margin-top: 50px;
}
.hire {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  /* color: rgb(241, 104, 6); */
  padding: 8px 20px;
  /* border: 1px solid #fff; */
  /* border: 2px solid rgb(241, 104, 6); */
  border-radius: 15px 0px 15px 0px;
  font-size: 17px;
  /* background: transparent; */
  background: rgb(241, 104, 6);

  position: relative;
  cursor: pointer;
  text-align: center;
  
  
}
.hire:hover {
  border: 2px solid rgb(241, 104, 6);
  /* background: #f44336; */
  /* background: rgb(241, 104, 6); */
  background: #13547a;
  /* color: rgb(241, 104, 6); */
  transition: 1s;
}
.bubbles img {
  width: 30px;
  animation: bubble 7s linear infinite;
}
.bubbles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: -50px;
}
@keyframes bubble {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(-80vh);
    opacity: 0;
  }

}
.bubbles img:nth-child(1) {
  animation-delay: 3s;
}
.bubbles img:nth-child(2) {
  animation-delay: 1s;
}
.bubbles img:nth-child(3) {
  animation-delay: .5s;
}
.bubbles img:nth-child(4) {
  animation-delay: 6s;
}
.bubbles img:nth-child(5) {
  animation-delay: 2s;
}
.bubbles img:nth-child(6) {
  animation-delay: 5s;
}
.bubbles img:nth-child(7) {
  animation-delay: 3.5s;
}
.bubbles img:nth-child(8) {
  animation-delay: 5.5s;
}
.bubbles img:nth-child(9) {
  animation-delay: 7.5s;
}
.bubbles img:nth-child(10) {
  animation-delay: 1.5s;
}
@media(max-width: 650px) {
  .port-f {
    height: 500px;
  }
  .roller-div2 {
    width: 50px;
    height:50px;
    background: #13547a;
    border: 2px solid rgb(241, 104, 6);
    border-radius: 10px;
  }
  .roller-div {
    width: 0;
    height: 0;
  }
}
@media(max-width: 370px) {
  .hire {
    padding: 5px 8px;
    font-size: 13px;
    width: 100%;
  }
  
}
@media(max-width: 450px) {
  @keyframes bubble {
    100% {
      transform: translateY(-700px);
      opacity: 0;
    }
  }
  .tytles {
    font-size: 20px;
  }
  .the {
    font-size: 14px;
  }
}

@media(max-width: 850px) {
  .port-description {
    width: 90%;
  }
  .port-description h1 {
    font-size: 25px;
  }
  .roller {
    height: 90px;
    box-sizing: border-box;
  }
  
  .bubbles img {
    width: 20px;
  }
  .hire-div {
    max-width: 190px;
  }
  .hire-Outerdiv {
    max-width: 55%;
  }
}
.remarks-container {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
}
.testimony {
  /* color: rgb(138, 214, 24); */
  color: #fff;

}
.except {
  color: rgb(241, 104, 6);
}
.get {
  color: #fff;

}
.x-trans {
  transform: translateX(50%);
  transition: transform 700ms ease-in;
}
.x-trans.appear {
  transform: translateX(0);
}
.remarks {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin-top: 4rem;
  gap: 1rem;

}
.rm-all {
  display: flex;
  flex-direction: column;
  background-color: #256b93;
  /* margin: 15px; */
  text-align: center;
  transition-delay: 2s;
  transition: var(--transition);
  border-radius: 10px;
  
}
.rm-all:hover {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
}
.rm-tittle {
  color: rgb(241, 104, 6);
}

.rm-name {
  width: 100px;
  height: 100px;
  /* background-color: rgb(234, 63, 131); */
  background: linear-gradient(#D6FF7F, #0083CC);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: auto;

}
.slide-in {
  opacity: 0;
  transition: opacity 250ms ease-in;

  transform: translateX(-50%);
  transition: transform 700ms ease-in-out;
}
.slide-in.appear {
  opacity: 1;
  
  transform: translateX(0);
}
@media(max-width: 850px) {
  .remarks {
    display: flex;
    flex-direction: column;
  }

}
@media(max-width: 850px) {
  .rm-tittle {
    font-size: 18px;
  }
}
@media(max-width: 450px) {
  .rm-name {
    width: 70px;
    height: 70px;
  }
  .rm-name h3 {
    font-size: 15px;
  }

}
/* ****************PORTFOLIO-FORM****************** */

.socials-main {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #256b93;
  margin-top: 4rem;
  padding-top: 20px;
}

.socials-form {
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  gap: 1rem;
  padding-bottom: 20px;
  
}
.popup {
  width: 400px;
  /* background: #00ad7c; */
  background: #13547a;
  /* background: #80d0c7; */
  border-radius: 6px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  text-align: center;
  padding: 0 30px 30px;
  visibility: hidden;
  transition: transform 0.4s, top 0.4s ;
  border: 1px solid rgb(241, 104, 6);

  z-index: 3;
}
.popup.pop-active {
  top: 50%;
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}
.popup h3 {
  color: #fff;
}
.popup .tick {
  font-size: 50px;
  margin-top: -15px;
  color: chocolate;
}
.OK-btn {
  box-sizing: border-box;
  width: 130px;
  padding: 10px 0;
  border: 2px solid rgb(241, 104, 6);
  background: transparent;
  color: rgb(241, 104, 6);
  font-weight: bolder;
  cursor: pointer;
  transition: 1s;
}
.OK-btn:hover {
  border: none;
  background-color: rgb(241, 104, 6);
  color: #fff;
}
.thank-ms {
  color: whitesmoke;
}

.input-div {
  display: flex;
  flex-direction: column;
  background-color: #13547a;
  padding: 10px;
  transform: translateX(50%);
  transition: transform 700ms ease-in-out;
}
.input-div input,
.input-div textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 17px;
  outline: none;
  border: 1px solid rgb(241, 104, 6);
}

.mysocials-div {
  color: #fff;
  transform: translateX(-50%);
  transition: transform 700ms ease-in-out;
}
.input-div.appear,
.mysocials-div.appear {
  transform: translateX(0);
}
.folio {
  color: rgb(241, 104, 6);
}
.email-div {
  margin-bottom: 10px;
}
.my-socials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  /* margin-top: 4rem; */
  gap: 1rem;
  width: 80px;
  color: rgb(241, 104, 6);
  width: 40%;
}
.email-div {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: center;
}
.formBtn {
  box-sizing: border-box;
  width: 130px;
  padding: 10px 0;
  border: 2px solid rgb(241, 104, 6);
  background: transparent;
  color: rgb(241, 104, 6);
  font-weight: bolder;
  cursor: pointer;
  transition: 1s;
}
.formBtn:hover {
  border: none;
  background-color: rgb(241, 104, 6);
  color: #fff;
}
.contactPage {
  height: 100vh;
  width: 100%;
  background: linear-gradient(#80d0c7, #13547a);
  margin-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

}
.in-touch {
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}
.in-touch h3 {
  color: #fff;
}
.in-touch span {
  color: rgb(241, 104, 6);

}
.form-icon {
  width: 30px;
  height: 30px;
  color: rgb(241, 104, 6);

}
.redClass {
  color: red;
  
}
@media(max-width: 850px) {
  .contactPage {
    height: 100%;
  }
  .in-touch {
    margin: 2rem;
  }
  .my-socials {
    width: 60%;
  }
  .socials-form {
    display: flex;
    flex-direction: column;
  }
  .formBtn {
    box-sizing: border-box;
    width: 100px;
    padding: 5px 0;
    
  }


}
@media(max-width: 450px) {
  .popup {
    width: 80%;
  }
}

/* ****************************start of HOME-projects*********************** */
.homeDiv {
  position: relative;
  top: 10%;
  background-color: #13547a;
  width: 100%;
  height: 100%;
  
}
.homeDiv p {
  color: #fff; 
}
.div-display {
  position: relative;
  width: 500px;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  background-color: #2f7298;

  margin: auto;
  overflow: hidden;
}
.recent-projects {
  width: 500px;
  height: 100%;
  margin: auto;
  text-align: center;
}
.project-title {
  color: #fff;
}
.rp-span {
  color: rgb(241, 104, 6);
}
.home-links {
  display: flex;
  
}

.home-maindiv {
  width: 100%;
  min-width: 500px;
  height: 300px;
  text-align: center;
  transition: transform 0.4s ease-in-out;

}
.hm-pro-h3 {
  /* color: rgb(241, 104, 6); */
  font-size: 18px;
  color: #fff;

}

/* .home-maindiv h3 {
  color: rgb(241, 104, 6);

} */
.home-photo {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */

}
/* .home-subdiv1 h3 {
  color: #9ac189;

} */
.home-subdiv2 h3 {
  /* color: rgb(134, 220, 5); */
  margin-top: -27px;
}
.home-subdiv2 button {
  margin-top: -10px;
}

/* .home-subdiv3 h3 {
  color: aquamarine;
  font-size: 18px;

} */
.home-maindivBtn {
  box-sizing: border-box;
  width: 130px;
  padding: 10px 0;
  border: 2px solid rgb(241, 104, 6);
  background: transparent;
  color: rgb(241, 104, 6);
  font-weight: bolder;
  cursor: pointer;
  transition: 1s;
}
.home-maindivBtn:hover {
  border: none;
  background-color: rgb(241, 104, 6);
  color: #fff;
}

/* home-btns */
.slideBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  opacity: .5;
  /* border: 2px solid rgb(155, 15, 38); */
  color: rgb(159, 10, 222);
  padding: 8px 15px;
  border-radius: 7px;
  font-size: 30px;
  border: none;
}
.slideBtn:hover {
  opacity: 1;
}
.slideBtn.slidePrev {
  left: 10px;
}
.slideBtn.slideNext {
  right: 10px;
}
/* .subdivP {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  
@media(max-width: 500px) {
  .recent-projects {
    width: 90%;
  }
  .div-display {
    width: 90%;
    height: 100%;
    
  }
  .home-links {
    width: 100%;
    margin: auto;
  }
  .home-maindiv {
    min-width: 100%;
    height: 100%; 
  }
  .home-maindiv img {
    margin-bottom: 30px;
  }

  .home-maindivBtn {
    width: 100px;
    padding: 5px 0;
    margin-bottom: 10px;
  }


}

/* <<<<<<<<<<PROJECTS>>>>>>>>>> */
.linkPhoto {
  width: 100%;
  height: 70%;
}
.bg-div {
  width: 100%;
  height: 100%;
  background: linear-gradient(#80d0c7, #13547a); 
}
.project-links {
  width: 70%;
  height: 100%;
  margin: auto;
  
  /* background-color: #256b93; */

}
.project-maindiv {
  width: 400px;
  height: 400px;
  /* border: 2px solid palevioletred; */
  display: inline-block;
  /* box-shadow: 2px 2px 2px 2px rgba(0.2, 0.2, 0.2, 0.25); */
  box-shadow: 0 4px 8px rgba(24, 22, 22, 0.65);
  margin: 8px;
  
}
.project-maindiv:hover {
  scale: 1.025;
  background: #12ac9a;
}
.project-links h3 {
  color: rgb(241, 104, 6);
}
.proLink {
  text-align: center;
}
.openBtn-div {
  margin: auto;
}
.openBtn {
  background-color: transparent;
  color: var(--clr-white);
  padding: 6px 15px;
  border-radius: 5px;
  font-weight: bolder;
  background-color: rgb(241, 104, 6);
  border: none;
  cursor: pointer;
  text-align: center;

}
.openBtn:hover {
  background-color: rgb(230, 140, 76);
}
.project-maindiv h3 {
  color: white;
}

.project-subdiv1,
.project-subdiv2,
.project-subdiv3,
.project-subdiv4 {
  margin: 20px;
  /* width: 100%; */
  height: 90%;
  /* border: 2px solid rgb(0, 255, 4); */
  border: 1px solid rgb(241, 104, 6);

}
@media(max-width: 700px) {
  .project-maindiv {
    width: 100%;
    height: 100%;
  }
  .project-links h3 {
    font-size: 15px;
  }
}





/*
=============== 
Tours
===============
*/



/* <<<<<<<<<<<<<<<<QUIZ SECTION>>>>>>>>>>>>>>>>>>>> */


.formPosition {
  position: relative;
  top: 0;

  width: 100%;
  height: 100%; 
  
  background: linear-gradient(#fbd786, #f7797d);

}
.formPosition.timer-mainDiv {
  position: relative;
  top: 0;
  

}
.quiz-description {
  width: 70%;
  height: 100%;
  /* background-color: #9f811e; */
  background: #13547a;
  /* color: rgb(36, 241, 5); */
  /* color: rgb(17, 242, 171); */
  color: white;
  margin: auto;
  padding: 10px;
  text-align: center;
  border-radius: 20px 0px 20px 0px;
}
.quiz-description h4 {
  letter-spacing: 2px;
  text-transform: none;

}

.keyWord {
  color: rgb(241, 104, 6);
}
.questList {
  list-style: none;
}
.radioBtn {
  margin-right: 10px;
}
.timer-mainDiv .displace-form {
  position: absolute;
  width: 100%;
  margin-top: 50px;
  margin: auto;
  background: linear-gradient(#fbd786, #f7797d);
  padding: 50px;
}

.timer-mainDiv .trial-timer {
  position: fixed;
  /* left: 50%; */
  right: 10%;
  
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(86, 135, 134); */
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
  background: #13547a;
  
  z-index: 3;
  /* margin: auto; */
  /* text-align: center; */
  

}
.trial-timer h3 {
  font-size: 30px;
  /* color: #c22df8; */
  /* color: rgb(241, 104, 6); */
  color: white;

  
}
.scoreDiv {
  width: 100%;
  height: 100vh;
  
}
.theQuestion {
  letter-spacing: normal;
  text-transform: none;
}
.score-subDiv {
  width: 60%;
  height: 40%;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.score-subDiv h3 {
  text-transform: none;
  
}
.quiz-btn {
  /* background-color: rgb(63, 35, 15); */
  background: #13547a;
  color: rgb(241, 104, 6);
  font-size: 20px;
  padding: 6px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: center;
  /* margin-left: auto; */

}
.quiz-btn:hover {
  color: #fff;
  background-color: rgb(241, 104, 6);
  cursor: pointer;

}
.scoreBtn {
  background-color: transparent;
  color: var(--clr-white);
  padding: 6px 15px;
  border-radius: 5px;
  font-weight: bolder;
  background-color: rgb(241, 104, 6);
  border: none;
  cursor: pointer;
  text-align: center;
}
.scoreBtn:hover {
  background: #13547a;

}

@media(max-width: 700px) {
  .timer-mainDiv .displace-form {
    padding: 50px 5px;
  }
  .score-subDiv {
    width: 95%;
    height: 40%;
  }
  .quiz-description {
    width: 100%;
  }
  .quiz-description h4 {
    letter-spacing: normal;
    
  }
  .theQuestion {
    font-size: 12px;
    letter-spacing: normal;
  }
  
}
@media(max-width: 350px) {
  .quiz-description h4 {
    font-size: 12px;
  }
  .quiz-btn {
    font-size: 15px;
  }
  .trial-timer h3 {
    font-size: 20px;
    
  }
  .trial-timer {
    height: 30px;
  }
  .score-subDiv h3 {
    font-size: 15px;
  }

  


}

/* <<<<<<<<<<<<<<<<END of QUIZ SECTION>>>>>>>>>>>>>>>>>>>> */

/* <<<<<<<<<<<<<<<<FACT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.main-FactsDiv {
width: 100%;
height: 100%;
/* background: #9ac189; */
background: #13547a;

margin-bottom: -30px;
}
.the-facts {
  width: 80%;
  height: 100%;
  /* background: #64695f; */
  margin: auto;
}
.main-title h2 {
  color: rgb(241, 104, 6);
  padding: 10px;
}
.main-ReloadDiv {
  width: 100%;
  height: 100vh;
  background: #13547a;
  
  margin-bottom: -30px;
}
.ReloadDiv {
  width: 60%;
  height: 150px;
  /* background-color: #9f811e; */
  /* background: rgb(216, 184, 184); */
  background: rgb(219, 168, 168);
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 10px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
}
.ReloadDiv button {
  width: 120px;
  background: rgb(241, 104, 6);
  border: 1px solid var(--clr-red-dark);
  color: #fff;
  border-radius: 10px 0px 10px 0px;
  font-weight: bolder;
}
.ReloadDiv button:hover {
  background-color: rgb(247, 161, 100);
  border: 1px solid rgb(241, 104, 6);
  color: #13547a;  
}



/* <<<<<<<<<<<<<<<< END of FACT>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.game-mainDiv {
  /* background: #256b93; */
  /* background: linear-gradient(#DF98FA, #9055FF); */
  background: #13547a;
  height: 100vh;
  padding-top: 60px;
}
.GAME {
  height: 400px;
  width: 70%;
  margin: auto;
  padding: 20px;
  background-color: #c1b99c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.g-desc {
  height: 50px;
  /* background: #bd2e2e; */
  /* color: white; */
  color: rgb(241, 104, 6);
  margin-bottom: 10px;
  text-align: center;
  /* background: rgb(241, 104, 6); */


}

.testWords {
  margin-top: 10px;
  height: 50px;
  /* background: #9a57ed; */
  background: #fbd490;
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testWords p {
  color: #00ad7c;
  font-weight: bolder;
  margin-top: 12px;
}
.typeSpace {
  margin-top: 20px;
  width: 100%;
}
.txArea {
  border: 7px solid greenyellow ;
  outline: none;
  padding: 5px;
  width: 100%;
}
.game-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.g-timer {
  font-size: 40px;
  color: yellow;
}
.resetBtn {
  padding: 8px 15px;
  /* background: #b43de3; */
  background: rgb(241, 104, 6);
  border: none;
  border-radius: 5px;
  /* color: rgb(59, 241, 59); */
  color: #fff;
  cursor: pointer;
  letter-spacing: 2px;
  font-weight: bolder;

}

.startBtn {
  display: none;
  padding: 8px 15px;
  /* background: #b43de3; */
  background: rgb(241, 104, 6);
  border: none;
  border-radius: 5px;
  /* color: rgb(59, 241, 59); */
  color: #fff;
  cursor: pointer;
  letter-spacing: 2px;
  font-weight: bolder;
  margin-right: 20px;
  
}
.resetBtn:hover,
.startBtn:hover {
  background: #ece4d4;
  color: #5e047f;
  transition: 1s;
  
}
@media(max-width: 650px) {
  .GAME {
    width: 90%;
  }
  
  .testWords {
  
    height: 75px;
    padding: 6px;
  }


}
@media(max-width: 450px) {
  .game-mainDiv {
    height: 90vh;
    overflow-y: hidden;
  }

  .g-desc h2 {
    font-size: 25px;
  }
}
@media(max-width: 500px) {
  .startBtn {
    display: inline-block;
    padding: 8px 15px;
    background: #b43de3;
    background: rgb(241, 104, 6);

    border: none;
    border-radius: 5px;
    /* color: rgb(59, 241, 59); */
    color: #fff;

    cursor: pointer;
    letter-spacing: 2px;
    font-weight: bolder;
    margin-right: 20px;
    
  }
}




